const userDefaults = {
  uid: 0, // From Auth
  displayName: "user", // From Auth
  status: "online",
  userRole: "admin",
};

// /////////////////////////////////////////////
// State
// /////////////////////////////////////////////

const state = {
  AppActiveUser: JSON.parse(localStorage.getItem("userInfo")) || userDefaults,
  test: 123,
};

export default state;
