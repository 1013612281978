// axios
import axios from "axios";
import store from "./store/store";

const domain = "";
const api = axios.create({
  domain,
  // You can add your headers here
});

api.interceptors.request.use(
  function (config) {
    const JWT_TOKEN = store.getters.getToken;

    if (JWT_TOKEN != null) {
      config.headers = {
        Authorization: JWT_TOKEN,
        Accept: "application/json",
      };
    }
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export default api;
