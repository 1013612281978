import router from "@/router";
import axios from "axios";
const state = {
  token: localStorage.getItem("token") || null,
  userData: {},
  balance: null,
  mock: localStorage.getItem("mock") === "true",
};

const getters = {
  getToken: (state) => {
    return state.token;
  },
  getUserData: (state) => {
    return state.userData;
  },
  getBalance: (state) => {
    return state.balance;
  },
  getMock: (state) => {
    return state.mock;
  },
};

const actions = {
  setToken({ commit }, token) {
    return new Promise((resolve) => {
      let header_token = `Bearer ${token}`;
      commit("SET_TOKEN", header_token);
      resolve(header_token);
    });
  },
  setBalance({ commit }, balance) {
    return new Promise((resolve) => {
      commit("UPDATE_BALANCE", balance);
      resolve(balance);
    });
  },
  setUserData({ commit }, userData) {
    return new Promise((resolve) => {
      commit("UPDATE_USER_DATA", userData);
      resolve(userData);
    });
  },
  setMock({ commit }, mock) {
    return new Promise((resolve) => {
      commit("UPDATE_MOCK", mock);
      resolve(mock);
    });
  },
  setDisplayName({ commit }, name) {
    return new Promise((resolve) => {
      commit("UPDATE_USER_INFO", { displayName: name });
      resolve(name);
    });
  },
  // checkAuth({ commit, getters }) {
  //   return new Promise((resolve, reject) => {
  //     let header_token = getters.getToken;
  //     if (!header_token) {
  //       return reject({ success: false });
  //     }
  //     axios
  //       .get(`${process.env.VUE_APP_BACKEND_API_URL}/auth/info`, {
  //         headers: { Authorization: header_token },
  //       })
  //       .then(({ data }) => {
  //         if (data.success) {
  //           commit("UPDATE_USER_INFO", { displayName: data.username });
  //         } else {
  //           commit("DEL_TOKEN");
  //           reject(data);
  //           return router.push("/logout");
  //         }
  //         return resolve(data);
  //       })
  //       .catch((err) => {
  //         commit("DEL_TOKEN");
  //         reject(err);
  //         return router.push("/logout");
  //       });
  //   });
  // },
  async logout({ commit, getters }) {
    try {
      let header_token = getters.getToken;
      await axios.post(
        `${process.env.VUE_APP_BACKEND_API_URL}/api/auth/logout`,
        {},
        {
          headers: { authorization: header_token },
        }
      );
      commit("DEL_MOCK");
      commit("DEL_TOKEN");

      router.push("/login");
    } catch (error) {
      commit("DEL_MOCK");
      commit("DEL_TOKEN");

      router.push("/login");
      throw error;
    }
  },
};

const mutations = {
  UPDATE_MOCK(state, mock) {
    localStorage.setItem("mock", mock);
    state.mock = mock;
  },
  UPDATE_USER_DATA(state, userData) {
    localStorage.setItem("userData", JSON.stringify(JSON.userData));
    state.userData = userData;
  },
  UPDATE_BALANCE(state, balance) {
    localStorage.setItem("balance", JSON.stringify(balance));
    state.balance = balance;
  },
  SET_TOKEN(state, token) {
    localStorage.setItem("token", token);
    state.token = token;
  },
  DEL_TOKEN(state) {
    localStorage.removeItem("token");
    state.token = null;
  },
  DEL_MOCK(state) {
    localStorage.removeItem("mock");
    state.mock = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
