import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/store";

Vue.use(VueRouter);

let routes = [
  {
    // will match everything
    path: "*",
    component: () => import("../views/404.vue"),
  },
  {
    path: "/",
    name: "layout",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "home",
    layout: "dashboard",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/room/:game?",
    name: "Room",
    layout: "dashboard",
    component: () => import("../views/Room.vue"),
  },
  {
    path: "/result",
    name: "Result",
    layout: "dashboard",
    component: () => import("../views/Result.vue"),
  },

  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("../views/Logout.vue"),
  },
];

// Adding layout property from each route to the meta
// object so it can be accessed later.
function addLayoutToRoute(route, parentLayout = "default") {
  route.meta = route.meta || {};
  route.meta.layout = route.layout || parentLayout;

  if (route.children) {
    route.children = route.children.map((childRoute) =>
      addLayoutToRoute(childRoute, route.meta.layout)
    );
  }
  return route;
}

routes = routes.map((route) => addLayoutToRoute(route));

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    }
    return {
      x: 0,
      y: 0,
      behavior: "smooth",
    };
  },
});

router.beforeEach(async (to, from, next) => {
  // some function to check token if request was sent.

  if (store.getters.getToken) {
    if (to.path == "/login") next("/home");
    next();
  } else if (!store.getters.getToken && to.path != "/login") {
    store
      .dispatch("logout")
      .then((r) => console.log(r))
      .catch((error) => console.log(error));
    next("/login");
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
