<!-- 
	This is the main page of the application, the layout component is used here,
	and the router-view is passed to it.
	Layout component is dynamically declared based on the layout for each route,
	specified in routes list router/index.js .
 -->

<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
export default {
  mounted() {
    this.checkRespone();
    this.getCurrentProfile();
  },
  computed: {
    // Sets components name based on current route's specified layout, defaults to
    // <layout-default></layout-default> component.
    layout() {
      return "layout-" + (this.$route.meta.layout || "default").toLowerCase();
    },
  },
  methods: {
    checkRespone() {
      this.$http.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
          if (error.response.status === 401) {
            this.$store.dispatch("logout").then(() => {
              this.$router.push("/login").then(() => {
                // window.location.reload();
              });

              return Promise.reject(error);
            });
          }
        }
      );
    },
    getCurrentProfile() {
      this.$http
        .get(`${process.env.VUE_APP_BACKEND_API_URL}/api/me/profile`)
        .then(({ data }) => {
          this.$store.dispatch("setMock", data.isMock).then((data) => {
            this.updateBalance();
            this.getBalance();
            return data;
          });
        })
        .catch((err) => {
          return err;
        });
    },

    getBalance() {
      this.$http
        .get(
          `${process.env.VUE_APP_BACKEND_API_URL}/api/me/balance?isMock=${this.$store.getters.getMock}`
        )
        .then(({ data }) => {
          this.$store.dispatch("setBalance", data.balance).then((data) => {
            return data;
            // this.balance = data
            //   .toString()
            //   .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          });
        })
        .catch((err) => {
          return err;
        });
    },
    updateBalance() {
      if (this.$store.getters.getMock) {
        this.sockets.unsubscribe(`wallet-updated`);
        try {
          this.sockets.subscribe(`mock-wallet-updated`, (data) => {
            this.$store.dispatch("setBalance", data.balance).then((data) => {
              return data;
              // this.balance = data
              //   .toString()
              //   .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            });
          });
        } catch (error) {
          return error;
        }
      } else {
        this.sockets.unsubscribe(`mock-wallet-updated`);
        try {
          this.sockets.subscribe(`wallet-updated`, (data) => {
            this.$store.dispatch("setBalance", data.balance).then((data) => {
              return data;
              // this.balance = data
              //   .toString()
              //   .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            });
          });
        } catch (error) {
          return error;
        }
      }
    },
  },
};
</script>

<style lang="scss"></style>
