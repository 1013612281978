const getters = {
  getDisplayName: (state) => {
    return state.AppActiveUser?.username ?? "";
  },
  getUserId: (state) => {
    return state.AppActiveUser?.user_id ?? "";
  },
};

export default getters;
